import { CheckoutForm } from '@components/CheckoutForm';
import { Button } from '@lib/Button';
import { Spinner } from '@lib/Loader';
import { httpClient } from '@network/api';
import { useDeleteShipment } from '@network/hooks/shipment';
import { BaseError } from '@network/interface';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Modal } from '.';

export const PaymentModal = ({
  isOpen,
  onClose,
  amount,
  isCreate,
  parcel_id,
  shipmentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  amount: number | null;
  isCreate?: boolean;
  parcel_id?: string;
  shipmentId?: string;
}) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  const [clientSecret, setClientSecret] = useState<string>('');

  const [loading, setLoading] = useState({
    pk: false,
    sk: false,
  });

  useEffect(() => {
    setLoading((prev) => ({ ...prev, pk: true }));
    httpClient
      .get<{ publishable_key: string }>('/payment/config')
      .then((data) => setStripePromise(loadStripe(data?.data?.publishable_key)))
      .catch((e) => {
        toast.error(e.message, { id: 'error' });
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, pk: false }));
      });
  }, []);

  useEffect(() => {
    if (amount) {
      setLoading((prev) => ({ ...prev, sk: true }));
      httpClient
        .post<{ secret: string }>('/payment/create_payment_intent', { amount })
        .then((data) => setClientSecret(data?.data?.secret))
        .catch((e: BaseError) => {
          toast.error(e.response?.data?.message, { id: 'error2' });
        })
        .finally(() => {
          setLoading((prev) => ({ ...prev, sk: false }));
        });
    }
  }, [amount]);

  const { handleDeleteShipment, isLoading } = useDeleteShipment(onClose);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="w-full border-b border-b-gray-300 p-3 text-xl font-bold">Make Payment</div>
      <div className="p-10">
        {loading.pk || loading.sk ? (
          <div className="w-full flex items-center justify-center">
            <Spinner />
          </div>
        ) : stripePromise && clientSecret ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm parcelId={parcel_id} />
          </Elements>
        ) : null}
      </div>
      <div className="w-full border-t border-t-gray-300 p-3">
        <div className="w-full flex gap-4 mt-4 justify-end">
          <div>
            <Button
              label="Cancel"
              isLoading={isLoading}
              className={`!bg-transparent !border !border-solid !border-red-500 hover:border-red-600 !text-red-500 py-4`}
              onClick={() => {
                if (isCreate) {
                  handleDeleteShipment(shipmentId ?? '');
                } else {
                  onClose();
                }
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
