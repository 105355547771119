import { useAppSelector } from '@hooks/useAppSelector';
import { Button } from '@lib/Button';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

export const CheckoutForm = ({
  handleCreateShipment,
  parcelId,
}: {
  parcelId?: string;
  handleCreateShipment?: (callback?: (val?: string) => void) => void;
}) => {
  const { user } = useAppSelector((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState<string | null | undefined>(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleStripePaymentAndRedirections = async (parcelId2?: string) => {
    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setPaymentError(submitError.message);
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-complete?parcel=${parcelId2 || parcelId}`,
        receipt_email: user?.email,
      },
    });

    if (error) {
      setPaymentError(error?.message);
      setPaymentSuccess(false);
    } else {
      // Make a request to your backend to process the payment
      // You can send the paymentMethod.id to your server
      setPaymentError(null);
      setPaymentSuccess(true);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    if (handleCreateShipment && !parcelId) {
      handleCreateShipment?.(
        async (parcelId2) => await handleStripePaymentAndRedirections(parcelId2)
      );
    } else {
      await handleStripePaymentAndRedirections();
    }
  };

  return (
    <div>
      <PaymentElement options={{ layout: 'tabs' }} />
      {paymentError && (
        <div className="text-red-500 bg-red-200 rounded-md p-4 mt-5">Error: {paymentError}</div>
      )}
      {paymentSuccess && <div>Payment succeeded!</div>}
      <Button
        label="Pay Now"
        className="py-4 w-full rounded-md mt-8"
        onClick={handleSubmit}
        isLoading={isProcessing}
        disabled={isProcessing}
      />
    </div>
  );
};
