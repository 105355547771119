import { httpClient, useHttpPrivate } from '@network/api';
import { BaseError, Endpoints } from '@network/interface';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { updateUser } from '@app/index';

export const useUpdateUser = () => {
  const httpPrivate = useHttpPrivate();
  const dispatch = useAppDispatch();
  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const res = await httpPrivate.post<any>(Endpoints.UPDATE_USER, values);
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success('Success', { id: 'success' });

        dispatch(updateUser({ user: data?.data }));
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: 'error' });
      },
    }
  );

  const onSubmit = (values: any) => {
    mutate(values);
  };

  const user = useAppSelector((state) => state.user?.user);

  const initialValues = {
    phone: '',
    postal_code: '',
    address: '',
  };

  const validationSchema = yup.object().shape({
    phone: yup.string().required('This field is required!'),
    postal_code: yup.string().required('This field is required!'),
    address: yup.string().required('This field is required!'),
  });

  const { dirty, errors, values, handleChange, handleSubmit, isValid, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const isDisabled = !dirty || !isValid;

  useEffect(() => {
    setFieldValue('phone', user?.phoneNumber);
    setFieldValue('postal_code', user?.postal_code);
    setFieldValue('address', user?.address);
  }, []);

  return { isLoading, values, errors, handleChange, handleSubmit, isDisabled };
};

export const useChangePassword = () => {
  const httpPrivate = useHttpPrivate();
  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const res = await httpPrivate.post<any>(Endpoints.CHANGE_PASSWORD, values);
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success('Password Updated', { id: 'success' });
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: 'error' });
      },
    }
  );

  const onSubmit = (values: any) => {
    mutate(values);
  };

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('This field is required'),
    newPassword: yup.string().required('This field is required'),
    confirmNewPassword: yup.string().required('This field is required'),
  });

  const { dirty, errors, values, handleChange, handleSubmit, isValid } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const isDisabled = !dirty || !isValid;

  return { isLoading, values, errors, handleChange, handleSubmit, isDisabled };
};
