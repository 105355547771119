import React, { useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { TrackForm } from './TrackForm';
import { SendParcelForm } from './SendParcelForm';

export const Hero = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page')?.toLocaleLowerCase();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') {
      setSearchParams('?page=send');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="w-full h-[100vh] 2xl:h-[85vh]" id="home">
      <div
        className={`bg-hero_banner bg-fixed w-full h-full flex items-center transition duration-300 bg-cover`}
      >
        <div className="w-[90%] 2xl:w-[60%] mx-auto h-[80%]">
          <div className="bg-white w-full xl:w-1/2 2xl:w-[60%] min-h-[30%] rounded-xl overflow-hidden">
            <div className="w-full flex items-center justify-between">
              <Link
                to="?page=send"
                className={`w-1/2 p-4 cursor-pointer flex items-center justify-center transition duration-300 ${
                  page === 'send' ? 'bg-white' : 'bg-gray-300'
                } `}
              >
                Send
              </Link>
              <Link
                to="?page=track"
                className={`w-1/2 p-4 cursor-pointer flex items-center justify-center transition duration-300 ${
                  page === 'track' ? 'bg-white' : 'bg-gray-300'
                } `}
              >
                Track
              </Link>
            </div>
            {page === 'send' ? <SendParcelForm /> : <TrackForm />}
          </div>
        </div>
      </div>
    </div>
  );
};
