import React from 'react';

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: string;
  required?: boolean;
  name?: string;
  suffix?: React.ReactElement;
  placeholder?: string;
  setFieldValue?: any;
  options?: { key: string; value: string }[];
}

export const Select = ({
  label,
  error,
  required,
  name,
  suffix,
  setFieldValue,
  options,
  ...props
}: IProps) => {
  return (
    <div className="w-full mb-4">
      {label ? (
        <label className="text-gray-400 flex items-center">
          {label}
          {required ? <span className="text-primary-500">*</span> : null}
        </label>
      ) : null}
      <div className="w-full flex gap-2 items-center justify-between">
        <select
          name={name}
          className={`w-full p-3 rounded-md border border-gray-400 placeholder:text-gray-300 ${
            props.value ? 'text-black' : 'text-gray-400'
          }`}
          {...props}
        >
          {props.placeholder ? <option value="">{props.placeholder}</option> : null}
          {options?.map((opt) => (
            <option value={opt.value}>{opt.key}</option>
          ))}
        </select>
        {suffix ? suffix : null}
      </div>

      {error ? <small className="block text-red-300 text-sm">{error}</small> : null}
    </div>
  );
};
