import { clearQuoteDetails } from '@app/index';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { httpClient, useHttpPrivate } from '@network/api';
import {
  BaseError,
  Endpoints,
  ICreateShipment,
  ICreateShipmentResponse,
  IGetMyShipment,
  IInvoice,
  IShipment,
  QueryKeys,
} from '@network/interface';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

export const useGetQuote = () => {
  const [searchParams] = useSearchParams();
  const pickup = searchParams.get('pickup_postal_code') ?? '';
  const delivery = searchParams.get('delivery_postal_code') ?? '';
  const country = searchParams.get('country') ?? '';
  const weight = searchParams.get('weight') ?? '';

  const [estimate, setEstimate] = useState('');

  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const res = await httpClient.post(Endpoints.GET_QUOTE, values);
      return res.data;
    },
    {
      onSuccess: (data: any) => {
        setEstimate(data?.estimate);
        toast.success(data?.message, { id: 'sucsess' });
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: 'error' });
      },
    }
  );

  const onSubmit = (values: any) => {
    mutate(values);
  };

  useEffect(() => {
    if (pickup && delivery && weight && country) {
      onSubmit({
        postal_code_from: pickup,
        postal_code_to: delivery,
        country,
        weight: Number(weight),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, pickup, delivery, weight, estimate, country };
};

export const useGetShipmentPrice = ({
  country,
  weight,
  pickup,
  delivery,
}: {
  country: string;
  weight: string;
  pickup: string;
  delivery: string;
}) => {
  const [estimate, setEstimate] = useState('');

  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const res = await httpClient.post(Endpoints.GET_QUOTE, values);
      return res.data;
    },
    {
      onSuccess: (data: any) => {
        setEstimate(data?.estimate);
        toast.success(data?.message, { id: 'edadaddarror' });
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: 'error' });
      },
    }
  );

  const onSubmit = async (values: any) => {
    await mutate(values);
  };

  useEffect(() => {
    if (pickup && delivery && weight && country) {
      onSubmit({
        postal_code_from: pickup,
        postal_code_to: delivery,
        country,
        weight: Number(weight),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, weight, pickup, delivery]);

  return { isLoading, pickup, delivery, weight, estimate, country };
};

export const useCreateShipment = (callback?: () => void) => {
  const httpPrivate = useHttpPrivate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { quote } = useAppSelector((state) => state.app);
  const [parcel, setParcel] = useState<IShipment | null>(null);

  const { mutate, isLoading } = useMutation(
    async (values: any) => {
      const res = await httpPrivate.post<ICreateShipmentResponse>(Endpoints.CREATE_SHIPMENT, {
        ...values,
        receiver_phone_number: values.receiver_phone_number.toString(),
        shipper_phone_number: values.shipper_phone_number.toString(),
        total_gross_weight: values.weight.toString(),
        number_of_pieces: values.number_of_pieces.toString(),
        weight: values.weight.toString(),
      });
      return res.data;
    },
    {
      onSuccess: (data) => {
        toast.success(data?.message, { id: 'dadadf' });
        dispatch(clearQuoteDetails());

        setParcel(data?.shipment);
        if (callback) {
          callback?.();
        } else {
          navigate('/dashboard');
        }
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: 'error' });
      },
    }
  );

  const onSubmit = (values: ICreateShipment) => {
    mutate(values);
  };

  const initialValues: ICreateShipment = {
    number_of_pieces: '',
    preferred_payment_method: '',
    // receiver_building_number: "",
    receiver_email_address: '',
    receiver_fullname: '',
    receiver_phone_number: '',
    receiver_country: quote.country,
    receiver_street_name: '',
    shipment_content: '',
    // shipper_building_number: "",
    shipper_email_address: user.email,
    shipper_fullname: user.fullName,
    shipper_phone_number: user.phoneNumber,
    shipper_country: quote.country,
    shipper_street_name: '',
    total_gross_weight: quote.weight,
    weight: quote.weight,
    parcel_type: '',
    shipper_postal_code: quote.pickup,
    receiver_postal_code: quote.delivery,
    total_amount: '',
  };

  const validationSchema = yup.object().shape({
    shipper_email_address: yup
      .string()
      .email('Please provide a valid email address!.')
      .required('This field is required!.'),
    number_of_pieces: yup.string().required('This field is required!.'),
    preferred_payment_method: yup
      .string()
      .required('Please select your preferred method of payment!.'),
    // receiver_building_number: yup
    //   .string()
    //   .required("Recipient Building Number is required!."),
    receiver_email_address: yup
      .string()
      .email('Please provide a valid email address!.')
      .required('This field is required!.'),
    receiver_fullname: yup.string().required('This field is required!.'),
    receiver_country: yup.string().required('This field is required!.'),
    receiver_street_name: yup.string().required('This field is required!.'),
    shipment_content: yup.string().required('This field is required!.'),
    // shipper_building_number: yup
    //   .string()
    //   .required("Building Number is required!."),
    shipper_fullname: yup.string().required('This field is required!.'),
    shipper_phone_number: yup
      .string()
      .matches(
        // Regular expression pattern for a phone number (adjust as needed)
        /^\+?[1-9]\d{1,11}$/,
        // Error message if the phone number does not match the pattern
        'Invalid phone number'
      )
      .min(8, 'Phone number is too short')
      .max(12, 'Phone number is too long')
      .required('This field is required!.'),
    receiver_phone_number: yup
      .string()
      .matches(
        // Regular expression pattern for a phone number (adjust as needed)
        /^\+?[1-9]\d{1,11}$/,
        // Error message if the phone number does not match the pattern
        'Invalid phone number'
      )
      .min(8, 'Phone number is too short')
      .max(12, 'Phone number is too long')
      .required('This field is required!.'),
    shipper_country: yup.string().required('This field is required!.'),
    shipper_street_name: yup.string().required('This field is required!.'),
    total_gross_weight: yup.string().notRequired(),
    weight: yup.string().required('This field is required!.'),
    parcel_type: yup.string().required('This field is required!.'),
    shipper_postal_code: yup
      .string()
      .matches(/^[\w\s]{5,}$/, 'Invalid postal code')
      .required('This field is required!.'),
    receiver_postal_code: yup
      .string()
      .matches(/^[\w\s]{5,}$/, 'Invalid postal code')
      .required('This field is required!.'),
  });

  const { dirty, errors, values, handleChange, isValid, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const { isLoading: isLoadingShipperStreets, streets: shipperStreets } =
    useGetStreetsFromPostalCode(values.shipper_postal_code ?? '');
  const { isLoading: isLoadingReceiverStreets, streets: receiverStreet } =
    useGetStreetsFromPostalCode(values.receiver_postal_code ?? '');

  const shipperStreetOptions =
    shipperStreets?.map((str) => ({
      key: str,
      value: str,
    })) ?? [];

  const receiverStreetOptions =
    receiverStreet?.map((str) => ({
      key: str,
      value: str,
    })) ?? [];

  const isDisabled = !dirty || !isValid;

  return {
    isLoading,
    values,
    errors,
    handleChange,
    handleSubmit,
    isDisabled,
    setFieldValue,
    isLoadingReceiverStreets,
    isLoadingShipperStreets,
    shipperStreetOptions,
    receiverStreetOptions,
    parcel,
  };
};

export const useGetUserShipments = () => {
  const httpPrivate = useHttpPrivate();
  const [shipments, setShipments] = useState<IShipment[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { isLoading, isFetching, isRefetching } = useQuery(
    [QueryKeys.GET_USER_SHIPMENTS, page],
    async () => {
      const { data } = await httpPrivate.get<IGetMyShipment>(
        `${Endpoints.GET_USER_SHIPMENTS}?page=${page}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        setShipments(data?.data ?? []);
        setTotalPages(data?.total);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: 'error' });
      },
    }
  );

  return {
    isLoading: isLoading || isFetching || isRefetching,
    shipments,
    page,
    setPage,
    totalPages,
  };
};

export const useGetUserInvoices = () => {
  const httpPrivate = useHttpPrivate();
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { isLoading, isFetching, isRefetching } = useQuery(
    [QueryKeys.GET_USER_INVOICES, page],
    async () => {
      const { data } = await httpPrivate.get<any>(`${Endpoints.GET_USER_INVOICES}?page=${page}`);
      return data;
    },
    {
      onSuccess: (data) => {
        setInvoices(data?.data);
        setTotalPages(data?.total);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;

        toast.error(message, { id: 'error' });
      },
    }
  );

  return {
    isLoading: isLoading || isFetching || isRefetching,
    invoices,
    page,
    setPage,
    totalPages,
  };
};

export const useAddressBook = () => {
  const httpPrivate = useHttpPrivate();

  const user = useAppSelector((state) => state.user);

  const [addresses, setAddresses] = useState<any>({});
  const { isLoading } = useQuery(
    ['addresses'],
    async () => {
      const { data } = await httpPrivate.get<any>(`${Endpoints.GET_USER_ADDRESS_BOOK}`);
      return data;
    },
    {
      enabled: user.access_token ? true : false,
      onSuccess: (data) => {
        setAddresses(data);
      },
    }
  );

  return {
    isLoading,
    addresses,
  };
};

export const useTrackShipment = () => {
  const httpPrivate = useHttpPrivate();
  const [shouldSearch, setShouldSearch] = useState(false);
  const [value, setValue] = useState('');
  const [shipmentDetails, setShipmentDetails] = useState<IShipment | null>(null);
  const [searchParams] = useSearchParams();
  const tracking_code = searchParams.get('tracking_code');
  const navigate = useNavigate();

  useEffect(() => {
    if (tracking_code) {
      setValue(tracking_code);
      setShouldSearch(tracking_code ? true : false);
    } else {
      setShouldSearch(false);
    }
  }, [tracking_code]);

  const { isFetching, refetch } = useQuery(
    [QueryKeys.TRACK_SHIPMENT, { value }],
    async () => {
      const { data } = await httpPrivate.get(`${Endpoints.TRACK_SHIPMENT}?tracking_code=${value}`);
      return data;
    },
    {
      enabled: shouldSearch ? true : false,
      onSuccess: (data) => {
        setShipmentDetails(data?.data);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        setShouldSearch(false);
        toast.error(message, { id: 'track-result' });
      },
    }
  );

  const handleChange = (e: any) => {
    const val = e.target.value;
    setValue(val);
  };

  const handleTrack = () => {
    navigate(`/track-parcel?tracking_code=${value}`);
    refetch();
    setShouldSearch(true);
  };

  return {
    isLoading: isFetching,
    shipmentDetails,
    value,
    handleChange,
    handleTrack,
    disabled: isFetching || !tracking_code,
  };
};

export const useGetStreetsFromPostalCode = (postal_code: string) => {
  const [streets, setStreets] = useState<string[]>([]);

  const { isLoading } = useQuery(
    [QueryKeys.POSTAL_CODE_FROM_STREET, { postal_code }],
    async () => {
      const { data } = await httpClient.get<Array<string>>(
        `${Endpoints.POSTAL_CODE_FROM_STREET}?postal_code=${postal_code}`
      );
      return data;
    },
    {
      enabled: postal_code !== '' && postal_code !== undefined ? true : false,
      onSuccess: (data) => {
        setStreets(data ?? []);
      },
      onError: (error: BaseError) => {
        // const message = error?.response?.data?.message;
        // toast.error(message, { id: "error" });
      },
    }
  );

  return { isLoading, streets };
};

export const useConfirmPayment = () => {
  const httpPrivate = useHttpPrivate();
  const [searchParams] = useSearchParams();

  const shipment_id = searchParams.get('parcel');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (parcel_id: string) => {
      const { data } = await httpPrivate.post('/payment/confirm-payment', {
        parcel_id,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        // queryClient.invalidateQueries([QueryKeys.GET_USER_SHIPMENTS]);
        setSuccess(true);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        toast.error(message, { id: 'error' });
        setError(message);
      },
    }
  );

  const onSubmit = () => {
    mutate(shipment_id ?? '');
  };

  useEffect(() => {
    if (shipment_id) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, success, error };
};

export const useDeleteShipment = (callback: () => void) => {
  const httpPrivate = useHttpPrivate();

  const { mutate, isLoading } = useMutation(
    async (parcelId: string) => {
      const { data } = await httpPrivate.delete(`/shipment/${parcelId}`);
      return data;
    },
    {
      onSuccess: (data) => {
        callback?.();
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        toast.error(message, { id: 'error' });
      },
    }
  );

  const onSubmit = (id: string) => {
    mutate(id);
  };

  return { isLoading, handleDeleteShipment: onSubmit };
};
